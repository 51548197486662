import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import IndoorCameras from '../../../images/Products/Compare/Wiki_Tiles_P-Indoor_white.webp'
import OutdoorCameras from '../../../images/Products/Compare/Wiki_Tiles_P-Outdoor_white.webp'

import 'components/Products/Compare/App.css'

import BreadCrumbs from 'components/Layout/BreadCrumbs'
import NavButtons from 'components/Products/NavButtons'

import SEOHelmet from 'components/Layout/SEOHelmet'


const seodata = {
  title: 'Compare All INSTAR Camera Models',
  description: 'Compare All INSTAR Camera Models.',
  image: '/images/Search/QI_SearchThumb_SetupWizard.png',
  twitter: '/images/Search/QI_SearchThumb_SetupWizard.webp',
  locationEN: 'https://wiki.instar.com/en/Products/Compare/',
  locationDE: 'https://wiki.instar.com/de/Produkte/Vergleich/',
  locationFR: 'https://wiki.instar.com/fr/Products/Compare/'
}

const CompareCams = (props) => (
  <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        location={props.location} />

      <BreadCrumbs
          locationBC={props.location}
          locationEN={props.location.pathname}
          locationDE='/de/Produkte/Vergleich/'
          locationFR='/fr/Products/Compare/'
          crumbLabel="Compare Camera" />
          
      <NavButtons />

      <h1>Compare the INSTAR Camera Models</h1>

      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem>
        <Link to="/Products/Compare/Indoor_Cameras/">
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={IndoorCameras}
                    alt="INSTAR Innenkameras"
                  />
                </div>
              }
            />
          </Link>
      </EuiFlexItem>
      <EuiFlexItem>
          <Link to="/Products/Compare/Outdoor_Cameras/">
          <EuiCard
            textAlign="left"
            image={
              <div>
                <img
                  src={OutdoorCameras}
                  alt="INSTAR Aussenkameras"
                />
              </div>
            }
          />
        </Link>
        </EuiFlexItem>
        <EuiFlexItem>
        </EuiFlexItem>
    </EuiFlexGroup>
  </React.Fragment>
)

export default CompareCams